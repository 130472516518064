import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, useMediaQuery } from '@material-ui/core';

import { Blurbs } from './Blurbs';

const useStyles = makeStyles((theme) => ({
	perk: {
		background: theme.white,
		border: '1px solid #E5EAF4',
		boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.15)',
		borderRadius: '20px',
	},
	image: {
		boxShadow: '35px 35px  #2F7FC1',
		borderRadius: '20px',
		maxHeight: '700px',
		marginTop: '1.5rem',
		[theme.breakpoints.down('md')]: {
			maxHeight: '750px',
			marginBottom: '4rem',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	blurbSection: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		marginTop: '5.5rem',
		[theme.breakpoints.down('lg')]: {
			marginTop: '6rem',
		},
		[theme.breakpoints.down('1025')]: {
			marginTop: '1rem',
		},
		[theme.breakpoints.down('960')]: {},
	},
}));

export const ValueProps = ({ img, blurbs, header }) => {
	const classes = useStyles();
	const med = useMediaQuery('(max-width: 960px)');
	const isTablet = useMediaQuery('(max-width: 959px)', {
		defaultMatches: true,
	});
	const isDesktop = useMediaQuery('(min-width: 1140px)', {
		defaultMatches: true,
	});

	return (
		<Container>
			<Grid row container justifyContent='center' alignItems='center'>
				<Typography
					variant='h4'
					color='primary'
					style={{ fontWeight: 700, padding: '2rem 0', overflowX: 'hidden' }}>
					{header}
				</Typography>
			</Grid>

			<Grid
				container
				direction='row'
				justifyContent='space-evenly'
				spacing={isDesktop || isTablet ? 4 : 8}>
				<Grid
					item
					container
					direction='column'
					alignItems={med ? 'center' : 'flex-start'}
					xs={8}
					md={6}
					lg={6}>
					<GatsbyImage
						image={img.asset?.gatsbyImageData}
						alt='value image'
						className={classes.image}
					/>
				</Grid>
				<Grid item xs={12} sm={10} md={6} className={classes.blurbSection}>
					{blurbs.map((blurb, index) => (
						<Blurbs blurb={blurb} key={index} />
					))}
				</Grid>
			</Grid>
		</Container>
	);
};
