import React, { useRef, useContext, useState } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, Typography, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/Partners/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { Perks } from '../components/Partners/Perks';
import { ValueProps } from '../components/Partners/ValueProps';
import { PartnerCta } from '../components/Partners/PartnerCta';
// import { SingleTestimonial } from '../components/Testimonials/SingleTestimonial';
// import { TestimonialCarousel } from '../components/Testimonials/TestimonialCarousel';
import FormModalContext from '../context/FormModalContext';
import { HeroModal } from '../components/Partners/Hero/HeroModal';
import { CustomerLogos } from '../components/CustomerLogos';
// import { Form } from '../components/ContactForm/Form';
// import { FeatureHeroModal } from '../components/General/FeatureHeroModal';

const SingleTestimonial = loadable(() =>
	import('../components/Testimonials/SingleTestimonial')
);
const TestimonialCarousel = loadable(() =>
	import('../components/Testimonials/TestimonialCarousel')
);
const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#5A7184',
		lineHeight: 1.8,
		'& a': {
			color: theme.workwaveBlue,
		},
	},
	testimonialTitle: {
		color: theme.workwaveBlue,
		fontWeight: 700,
		padding: '2rem 0',
	},
	formPadding: {
		padding: '18rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '14rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '6rem 0',
		},
	},
}));

const PartnersPage = ({ data, location }) => {
	const partners = data.partners.edges[0].node;
	const classes = useStyles();
	const formRef = useRef();
	// const waveRef = useRef();

	// const height = waveRef.current && waveRef.current.clientHeight;

	// useEffect(() => {
	// 	console.log(height);
	// }, [height]);

	//stuff for modal and form
	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	const [heroModalOpen, setHeroModalOpen] = useState(false);

	//callback to prefill marketo forms
	// const callback = () => {
	// 	if (email) {
	// 		window.MktoForms2.onFormRender((form) => {
	// 			// console.log(form);
	// 			form.setValues({
	// 				Email: email,
	// 			});
	// 		});
	// 	}
	// };

	const {
		hero,
		metaDescription,
		metaTitle,
		perksHeader,
		perks,
		marketplaceLink,
		marketplaceText,
		blurbs,
		blurbImage,
		ctaSectionHeader,
		ctaSectionBody,
		ctaSectionCtaText,
		ctaSectionImage,
		testimonials,
		testimonialHeader,
		partnerContactForm,
		blurbHeader,
		customerLogos,
	} = partners;

	const md = useMediaQuery('(max-width: 960px)');
	const sm = useMediaQuery('(max-width: 600px)');
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero
				hero={hero}
				setHeroModalOpen={setHeroModalOpen}
				setFormModalOpen={setFormModalOpen}
			/>
			<div style={{ background: 'white', zIndex: 5 }}>
				<Perks
					perksHeader={perksHeader}
					perks={perks}
					marketplaceText={marketplaceText}
					marketplaceLink={marketplaceLink}
				/>
				<ValueProps img={blurbImage} blurbs={blurbs} header={blurbHeader} />
			</div>
			<Container style={{ paddingTop: sm ? '0' : '4rem' }}>
				<CustomerLogos customerLogosArray={customerLogos} />
			</Container>
			<PartnerCta
				header={ctaSectionHeader}
				body={ctaSectionBody}
				ctaText={ctaSectionCtaText}
				image={ctaSectionImage}
				setFormModalOpen={setFormModalOpen}
			/>
			<div
				style={{
					background: '#F8FBFF',
					paddingTop: sm ? '3rem' : '6rem',
					paddingBottom: '2rem',
					marginBottom: '-15px',
				}}>
				<Container>
					<Grid container direction='row' justify='center' alignItems='center'>
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							<Typography variant='h4' className={classes.testimonialTitle}>
								{testimonialHeader}
							</Typography>
						</Grid>
					</Grid>

					{md ? (
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='center'>
							<TestimonialCarousel
								carouselTestimonialArray={testimonials}
								ref={formRef}
							/>
						</Grid>
					) : (
						testimonials.map((testimonial, index) => (
							<SingleTestimonial key={index} testimonial={testimonial} />
						))
					)}
				</Container>
			</div>
			<WaveDownSVG height='213' width='100%' fill='#f4f8ff' />
			<div
				className={classes.formPadding}
				style={{
					backgroundImage: `url(${partnerContactForm.formBackground?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
					backgroundColor: '#002D5C',
					// marginBottom: `${height}px`,
				}}>
				<Form
					formId={partnerContactForm.marketoId}
					pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					contactForm={partnerContactForm}
					privacy
					modal={false}
					location={location}
				/>
			</div>
			<div>
				<WaveUpSVG width='100%' fill='#f4f8ff' />
			</div>
			<HeroModal
				open={heroModalOpen}
				setHeroModalOpen={setHeroModalOpen}
				wistiaLink={hero.wistiaLink}
			/>
		</>
	);
};

export const query = graphql`
	query PartnersPageQuery {
		partners: allSanityPartners {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						ctaText
						wistiaLink
						wistiaImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					blurbHeader
					perksHeader
					perks {
						title
						accentColor {
							hexValue
						}
					}
					marketplaceText
					marketplaceLink
					blurbImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					blurbs {
						title
						icon
						header
						body
						accentColor {
							hexValue
						}
					}
					customerLogos {
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					ctaSectionHeader
					ctaSectionBody
					ctaSectionCtaText
					ctaSectionImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					testimonialHeader
					testimonials {
						title
						header
						testimonialLogo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						header
						title
						testimonialText
						nameAndTitle
						videoVariant
						company
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					partnerContactForm {
						header
						formBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						marketoId
						pardotUrl
					}
				}
			}
		}
	}
`;
export default PartnersPage;
