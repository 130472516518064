import React from 'react';
// import { useElementSize } from 'use-element-size';
import { makeStyles } from '@material-ui/core/';

export const WaveUpHero = ({ fill, home, company }) => {
	const useStyles = makeStyles((theme) => ({
		waveUpHero: {
			width: '100%',
			position: 'relative',
			top: '200px',
			marginTop: '-180px',
			marginBottom: '-2px',
			[theme.breakpoints.up('xl')]: {
				paddingBottom: '4rem',
			},
			[theme.breakpoints.down('xl')]: {
				top: '200px',
				marginTop: '-180px',
			},
			[theme.breakpoints.down('lg')]: {
				top: '100px',
				marginTop: '-20px',
			},
			[theme.breakpoints.down('md')]: {
				marginTop: '0px',
			},
			[theme.breakpoints.down('xs')]: {
				top: '48px',
			},
		},
	}));
	const classes = useStyles();
	return (
		<svg
			className={classes.waveUpHero}
			role='presentation'
			style={{
				filter: company
					? 'drop-shadow(0px -10px 11px rgb(0 45 92 / 0.15))'
					: 'none',
			}}
			viewBox='0 0 2160 263'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				id='Wave'
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M2160 262.5H0V0C360 80 720 120 1080 120C1440 120 1800 80 2160 0V262.5Z'
				fill={fill}
			/>
		</svg>
	);
};
