import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
	fontColor: {
		color: theme.workwaveBlue,
	},
	header: {
		fontWeight: 550,
		color: theme.workwaveBlue,
		// marginLeft: '10px',
	},
	body: {
		marginTop: '1rem',
		marginBottom: '2rem',
		lineHeight: 1.4,
		fontFamily: ['Roboto', 'sans-serif'].join(),
		[theme.breakpoints.down('lg')]: {
			marginBottom: '1rem',
		},
	},
	icon: {
		border: `1px solid #F1F2F5`,
		background: '#F1F2F5',
		borderRadius: '8px',
		// padding: '1rem',
		// maxHeight: '50px',
		// maxWidth: '50px',
	},
	iconContainer: {
		marginRight: '16px',
		[theme.breakpoints.down('md')]: {
			marginRight: '27px',
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: '40px',
		},
	},
	text: {
		color: theme.workwaveBlue,
		marginBottom: '1rem',
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
		},
	},
}));

export const Blurbs = ({ blurb }) => {
	const med = useMediaQuery('(max-width: 960px)');
	const classes = useStyles();
	return (
		<Grid
			container
			style={{ marginBottom: '2rem' }}
			alignItems='flex-start'
			justifyContent='flex-start'
			direction='row'>
			<Grid item xs={1} className={classes.iconContainer}>
				<FontAwesomeIcon
					className={classes.icon}
					style={{
						color: blurb.accentColor.hexValue ?? '#002D5C',
						padding: '6px',
						width: '56px',
						height: '56px',
					}}
					icon={['fad', blurb.icon]}
				/>
			</Grid>

			<Grid item xs={8} style={{ marginTop: '.7rem' }}>
				<Typography variant='h5' className={classes.header}>
					{blurb.header}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant='body1' className={classes.body} style={{}}>
					{blurb.body}
				</Typography>
			</Grid>
		</Grid>
	);
};
