import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, Button, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	background: {
		background:
			'linear-gradient(-180deg, #002D5C -24.54%, #055291 12.46%, #2F7FC1 49.47%, #002D5C 91.02%)',
		// transform: 'rotate(-180deg)',
		padding: '3rem 0',
		marginTop: '7rem',
		[theme.breakpoints.down('md')]: {
			padding: '2rem 0',
			marginTop: '3rem',
		},
	},
	header: {
		fontWeight: 700,
		fontSize: '3.25rem',
		lineHeight: '1.1',
		color: theme.white,
		marginBottom: '2rem',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3rem',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '1rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: '2rem',
      fontSize: '2rem',
      lineHeight: 1.2,
		},
	},
	body: {
		color: theme.white,
		fontWeight: 400,
		lineHeight: '30px',
		letterSpacing: '.2px',
		marginBottom: '2rem',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '1rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: '2rem',
		},
	},
	image: {
		position: 'absolute',
		borderRadius: '100%',
		overflow: 'hidden',
		marginTop: '-4.25rem',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		[theme.breakpoints.down('md')]: {
			marginTop: 0,
			position: 'relative',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	button: {
		width: '45%',
		[theme.breakpoints.down('md')]: {
			width: '60%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '75%',
		},
	},
}));

export const PartnerCta = ({
	header,
	body,
	ctaText,
	image,
	setFormModalOpen,
}) => {
	const classes = useStyles();
  const sm = useMediaQuery('(max-width: 600px)');
	return (
		<div className={classes.background}>
			<Container>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					spacing={4}>
					<Grid
						item
						xs={12}
						md={6}
						container
						direction='column'
						justifyContent='space-evenly'>
						<Typography variant='h2' className={classes.header}>
							{header}
						</Typography>
						<Typography variant='h5' className={classes.body}>
							{body}
						</Typography>
						<Button
							variant='contained'
							size='large'
							color='secondary'
							onClick={() => setFormModalOpen(true)}
							className={classes.button}>
							{ctaText}
						</Button>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						direction='row'
						justifyContent='center'
            style={{ display: sm ? 'none' : ''}}>
						<GatsbyImage
							image={image?.asset.gatsbyImageData}
							className={classes.image}
						/>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};
