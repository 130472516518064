import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';

import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	card: {
		background: theme.white,
		// border: '1px solid #E5EAF4',
		// boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.15)',
		borderRadius: '20px',
		padding: '8px 0 8px 0',
		minHeight: '76px',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			// justifyContent: 'center',
			minHeight: '50px',
		},
	},
	hoverCard: {
		background: theme.white,
		border: '1px solid #E5EAF4',
		boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.15)',
		borderRadius: '20px',
		padding: '16px',
		minHeight: '88px',
		alignItems: 'start',
		justifyContent: 'start',
		display: 'flex',
		alignItems: 'start',
		transition: 'all .5s',
		cursor: 'pointer',
		'&:hover': {
			transform: 'scale(1.05)',
		},
		[theme.breakpoints.down('md')]: {
			minHeight: '84px',
		},
	},
	icon: {
		marginTop: '4px',
		marginRight: '8px',
		[theme.breakpoints.down('md')]: {},
	},
	cardText: {
		fontWeight: 700,
		lineHeight: 1.3,
		fontSize: '1.3rem',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.3rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.2rem',
		},
	},
}));

export const CheckmarkCards = ({ cards, link }) => {
	const classes = useStyles();

	const handleClick = (e, card) => {
		if (link) {
			navigate(card.link);
		} else {
			return;
		}
	};
	return (
		<Grid
			container
			direction='row'
			spacing={3}
			justifyContent='center'
			alignItems='center'>
			{cards.map((card, index) => (
				<Grid
					key={index}
					item
					xs={12}
					sm={8}
					md={6}
					lg={4}
					style={{ padding: '8px' }}>
					<Grid
						className={link ? classes.hoverCard : classes.card}
						onClick={(e) => (link ? handleClick(e, card) : null)}>
						<Grid>
							<FontAwesomeIcon
								icon={['fas', 'circle-check']}
								style={{
									color: card.accentColor.hexValue ?? '#002D5C',
								}}
								className={classes.icon}
								size='lg'
							/>
						</Grid>
						<Grid>
							<Typography
								variant='h6'
								color='primary'
								className={classes.cardText}>
								{card.title}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			))}
		</Grid>
	);
};
